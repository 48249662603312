import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { colors } from "styles"
import useApp from "hooks/useApp"
import Image from "components/Image"
import photoAltText from "lib/photo-alt-text"

const PhotoGrid = ({ photos, className }) => {
  const app = useApp()

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: `repeat(auto-fit, 200px)`,
        justifyContent: "center",
        gap: 20,
        padding: 20,
        "@media (max-width: 500px)": {
          gridTemplateColumns: "1fr 1fr",
        },
      }}
      className={className}
    >
      {photos.map(photo => (
        <Link
          to={`/photos/photo-${photo._id}/`}
          key={photo._id}
          css={{
            position: "relative",
            overflow: "hidden",
            ":after": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              transition: "opacity 200ms cubic-bezier(0, 3, 0.75, 1)",
              background: colors.white,
              opacity: 0,
            },
            ":hover:after": {
              opacity: 0.05,
            },
          }}
        >
          <Image
            {...photo.image}
            width={200}
            height={200}
            alt={photoAltText({
              subjects: photo.subjects,
              author: photo.author,
            })}
            css={[
              {
                display: "block",
                objectFit: "cover",
                objectPosition: "center",
                transition: "filter 500ms",
              },
              !app.nsfw &&
                photo.nsfw && {
                  "@supports (filter: blur(10px))": {
                    filter: `blur(20px)`,
                    opacity: 1,
                  },
                },
            ]}
          />
        </Link>
      ))}
    </div>
  )
}

export default PhotoGrid

PhotoGrid.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      nsfw: PropTypes.bool,
      image: PropTypes.shape({ asset: PropTypes.object.isRequired }).isRequired,
      subjects: PropTypes.arrayOf(
        PropTypes.shape({ alias: PropTypes.string.isRequired }).isRequired
      ),
    }).isRequired
  ).isRequired,
  className: PropTypes.string,
}
