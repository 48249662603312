import React, { useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import hex2rgb from "lib/hex2rgb"
import Button from "components/Button"
import useApp from "hooks/useApp"
import styles from "styles"
import Image from "./Image"
import photoAltText from "../lib/photo-alt-text"

const Photo = ({ caption, author, subjects = [], image, nsfw, className }) => {
  const app = useApp()
  const [showPreview, setShowPreview] = useState(false)

  const hidePreview = useCallback(() => setShowPreview(false), [])

  useEffect(() => {
    window.addEventListener("mouseup", hidePreview)
    return () => {
      window.removeEventListener("mouseup", hidePreview)
    }
  }, [hidePreview])

  const {
    metadata: { palette, dimensions },
  } = image.asset

  return (
    <figure
      css={{ margin: 0, "& + &": { marginTop: 30 } }}
      className={className}
    >
      <div
        css={{
          background: palette.selected.background,
          paddingBottom: `${Math.round(10000 / dimensions.aspectRatio) / 100}%`,
          position: "relative",
          overflow: "hidden",
          "@media (max-width: 1200px)": {
            minHeight: `calc((100vw - 40px) / ${dimensions.aspectRatio})`,
          },
        }}
      >
        {nsfw && !app.nsfw && (
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              opacity: showPreview ? 0 : 1,
              transitionProperty: "opacity, background",
              transitionDuration: "500ms",
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 3,
              padding: 24,
              userSelect: "none",
              background: `linear-gradient(
                  to bottom right,
                  ${hex2rgb(palette.a.background, 0.75)} 0%,
                  ${hex2rgb(palette.b.background, 0.75)} 100%
                )`,
            }}
          >
            <h3 css={{ fontWeight: 700 }}>NSFW Content</h3>
            <p css={{ fontSize: 14, maxWidth: "35em", margin: "16px auto" }}>
              This photo has been hidden because it contains content that could
              potentially be considered inappropriate for workplaces.
            </p>
            <Button
              onTouchStart={() => setShowPreview(true)}
              onMouseDown={() => setShowPreview(true)}
              onTouchEnd={hidePreview}
              onTouchCancel={hidePreview}
              onMouseUp={hidePreview}
              css={{ outline: 0, userSelect: "none" }}
            >
              Preview NSFW Content
            </Button>
            <small>Hold button to preview</small>
          </div>
        )}

        {/* Low Quality Image Preview */}
        {image.asset.metadata.lqip && (
          <img
            src={image.asset.metadata.lqip}
            css={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
              pointerEvents: "none",
              objectFit: "cover",
            }}
          />
        )}

        <Image
          {...image}
          alt={photoAltText({ subjects, author })}
          width={800}
          css={[
            {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 2,
              pointerEvents: "none",
              objectFit: "cover",
            },
            nsfw &&
              !app.nsfw && {
                transitionProperty: "opacity, filter",
                transitionDuration: "200ms, 400ms",
                transitionDelay: showPreview ? "100ms" : 0,
                opacity: showPreview ? 0.2 : 0,
                "@supports (filter: blur(10px))": {
                  filter: `blur(${showPreview ? 0 : 20}px)`,
                  opacity: 1,
                },
              },
          ]}
        />
      </div>
      <figcaption
        css={[
          {
            color: styles.lightGray,
            fontSize: 14,
            padding: "8px 0",
            "@media (max-width: 820px)": {
              padding: "8px 16px",
            },
          },

          subjects.length
            ? {
                display: "flex",
                justifyContent: "space-between",
              }
            : {
                textAlign: "center",
              },
        ]}
      >
        {subjects.length > 0 && (
          <div css={{ display: "flex", alignItems: "center" }}>
            <PhotoSubjectIcon css={{ marginRight: "0.7em" }} />{" "}
            {subjects.map(subject => (
              <span
                key={subject._id}
                css={{
                  "& + &": {
                    ":before": {
                      content: "', '",
                    },
                  },
                }}
              >
                {subject.options.buildPage ? (
                  <Link to={`/photos/of/${subject.slug.current}/`}>
                    {subject.alias}
                  </Link>
                ) : (
                  subject.alias
                )}
              </span>
            ))}
          </div>
        )}
        <div>{caption}</div>
      </figcaption>
    </figure>
  )
}

export default Photo

const PhotoSubjectIcon = props => (
  <svg width="18" viewBox="0 0 54 49" {...props}>
    <path
      fill="#444"
      fillRule="evenodd"
      d="M41.44 8.33h6.9c2.93 0 5.33 2.4 5.33 5.34V43c0 2.94-2.4 5.33-5.34 5.33H5.67A5.34 5.34 0 01.33 43V13.67c0-2.94 2.4-5.34 5.34-5.34h6.9l7.21-7.22a2.66 2.66 0 011.89-.78h10.66a2.66 2.66 0 011.89.78l7.22 7.22zM19.32 22.52a7.68 7.68 0 1115.36 0 7.68 7.68 0 01-15.36 0zm3.84 0a3.84 3.84 0 107.68 0 3.84 3.84 0 00-7.68 0zm-6.57 16.6a15.9 15.9 0 0020.82 0 6.91 6.91 0 00-5.57-3.15c-1.66.52-3.25.77-4.84.77-1.6 0-3.18-.26-4.84-.77a6.9 6.9 0 00-5.57 3.16zm15.02-7c3.44 0 6.45 1.64 8.4 4.15A15.89 15.89 0 0043 27c0-8.82-7.18-16-16-16s-16 7.18-16 16c0 3.46 1.11 6.66 2.98 9.27a10.64 10.64 0 018.41-4.15c.3 0 .65.1 1.1.23a11.81 11.81 0 007 0c.47-.13.82-.23 1.12-.23z"
    />
  </svg>
)

const personProps = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  alias: PropTypes.string.isRequired,
  url: PropTypes.string,
})

Photo.propTypes = {
  caption: PropTypes.string,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
  }),
  subjects: PropTypes.arrayOf(personProps),
  nsfw: PropTypes.bool,
  className: PropTypes.string,
  image: PropTypes.shape({
    asset: PropTypes.shape({
      url: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        dimensions: PropTypes.shape({
          aspectRatio: PropTypes.number.isRequired,
        }).isRequired,
        lqip: PropTypes.string,
        palette: PropTypes.shape({
          a: PropTypes.shape({ background: PropTypes.string.isRequired })
            .isRequired,
          b: PropTypes.shape({ background: PropTypes.string.isRequired })
            .isRequired,
          selected: PropTypes.shape({ background: PropTypes.string.isRequired })
            .isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}
