import React from "react"
import styles from "styles"

const Button = props => (
  <button
    css={{
      appearance: "none",
      border: 0,
      borderRadius: 2,
      padding: "8px 16px",
      cursor: "pointer",
      background: styles.purple,
      color: "#fff",
      display: "inline-block",
      fontWeight: 500,
      fontSize: 12,
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    }}
    {...props}
  />
)

export default Button
