const photoAltText = ({ prefix = "", author, subjects }) => {
  let alt = `${prefix} Photo`
  if (subjects.length) {
    alt = alt + ` of ${subjects.map(s => s.alias).join(" + ")}`
  }
  if (author) {
    alt = alt + ` by ${author.name}`
  }
  return alt.trim()
}

export default photoAltText
